import { SearchFacetsResult } from '@bridebook/toolbox/src/seo-facets';
import type { AreaFacets } from '@bridebook/toolbox/src/seo-facets/get-area-facets';
import type { QnA } from '@bridebook/toolbox/src/seo-facets/questions-and-answers';
import { IElasticSupplier, Slug } from '@bridebook/toolbox/src/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SearchFields from 'app-shared/lib/search/search-fields';
import { ILocationData } from 'app-shared/lib/search/types';
import { formatFiltersFromSearch } from 'lib/search/utils';
import { IFetchSearchPromiseResult } from 'lib/types';

export type BasicSearchState = {
  list: IElasticSupplier[];
  filters: Record<string, any>;
  totalResults: number;
  actualCategory: Slug;
  fields: SearchFields;
  facetsContent: SearchFacetsResult;
  faqContent: QnA[];
  areaFacets: AreaFacets;
  loaded: boolean;
  locationData: Record<string, ILocationData>;
};

const initialState: BasicSearchState = {
  list: [],
  totalResults: 0,
  actualCategory: 'venue',
  fields: { ...new SearchFields() },
  facetsContent: null,
  faqContent: [],
  areaFacets: {},
  loaded: false,
  locationData: {},
  filters: {},
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    startSearch: (state) => {
      state.loaded = false;
    },
    saveSearchResult: (state, { payload }: PayloadAction<IFetchSearchPromiseResult>) => {
      state.list = payload.results;
      state.totalResults = payload.totalResults;
      state.actualCategory = payload.fields?.type || '';
      state.fields = { ...payload.fields, page: payload.fields.page || 1 };
      state.facetsContent = payload.facetsContent;
      state.faqContent = payload.faqContent;
      state.areaFacets = payload.areaFacets;
      state.loaded = true;
      state.filters = formatFiltersFromSearch(payload.filters);
    },
    saveLocationData: (
      state,
      {
        payload,
      }: PayloadAction<{
        area: string;
        locationData: ILocationData;
      }>,
    ) => {
      state.locationData[payload.area] = payload.locationData;
    },
  },
});

export const { saveSearchResult, saveLocationData, startSearch } = searchSlice.actions;

export default searchSlice.reducer;
