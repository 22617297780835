import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type BasicSupplierState = {
  loaded: boolean;
  supplier: ISupplier | null;
};

const initialState: BasicSupplierState = {
  loaded: false,
  supplier: null,
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    saveSupplier: (draft, { payload }: PayloadAction<ISupplier>) => {
      draft.loaded = true;
      draft.supplier = payload;
    },
  },
});

export const { saveSupplier } = supplierSlice.actions;
export default supplierSlice.reducer;
