import { CohortNames, Experiment } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: BasicABTestsState = {
  // Only activated tests have a variant in the store
  variants: {},
  loaderVisible: false,
};

export interface BasicABTestsState {
  variants: Record<string, CohortNames>;
  tests?: Experiment[];
  loaderVisible: boolean;
}

const abTestsSlice = createSlice({
  name: 'abtests',
  initialState,
  reducers: {
    setVariant(state, { payload }: PayloadAction<{ id: string; variant: CohortNames }>) {
      state.variants[payload.id] = payload.variant;
    },
    setABTests(state, { payload }: PayloadAction<Experiment[]>) {
      state.tests = payload;
    },
    showFullpageLoader(state, { payload }: PayloadAction<boolean>) {
      state.loaderVisible = payload;
    },
  },
});

export const { setVariant, setABTests, showFullpageLoader } = abTestsSlice.actions;
export default abTestsSlice.reducer;
