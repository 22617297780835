import type { IAppProps } from 'pages/_app';
import { getI18n } from 'lib/i18n/getI18n';

/**
 * These functions optimize the translations namespaces returned by the nextjs
 * server when there is a soft navigation. There is a Nginx proxy used for
 * logged out for SEO that handles the header in a specific way, please keep
 * that in mind if doing changes to this logic and raise it with SEO.
 *
 * It is also used in multi-language to avoid re-loading all the common
 * namespaces when changing pages.
 */
export const mergeI18nTranslations = (Comp: IAppProps['Component']) => (props: any) => {
  try {
    // Not using a hook here so it doesn't re-render when i18n changes
    const i18n = getI18n();
    if (
      typeof window !== 'undefined' &&
      props?.pageProps?._nextI18Next?.initialI18nStore &&
      i18n.store?.data
    ) {
      const allLangs = Object.keys(props.pageProps._nextI18Next.initialI18nStore)
        .concat(Object.keys(i18n.store.data))
        .filter((v, i, a) => a.indexOf(v) === i);

      allLangs.forEach((lang) => {
        // This is not following best practices as it changes a prop and it
        // does it during render, however it is the only way found to replace
        // this content. The goal is to mutate the props because the i18n-next
        // hoc will create a new i18n instance. It does two merges so
        // namespaces from the server take preference.

        // This variable is to make clear that the namespaces from the server
        // take preference
        const allNamespaces = {};
        props.pageProps._nextI18Next.initialI18nStore[lang] =
          props.pageProps._nextI18Next.initialI18nStore[lang] || {};

        Object.assign(
          allNamespaces,
          i18n.store.data[lang] || {},
          props.pageProps._nextI18Next.initialI18nStore[lang],
        );

        Object.assign(props.pageProps._nextI18Next.initialI18nStore[lang], allNamespaces);
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('optimize-translations.tsx: error', error);
  }

  return <Comp {...props} />;
};
