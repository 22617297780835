import { type Market } from '@bridebook/toolbox/src/gazetteer';
import { AlternateLink } from 'lib/app/types';
import { env } from 'lib/env';
import { getFullLanguageTag } from 'lib/utils/i18n-routing/get-alternate-links';
import { isSupplierPage } from 'lib/utils/url';

/**
 * Returns an alternative market URL for the given path if it exists in the alternate links.
 * The path should not contain the market prefix.
 */
export const getAlternativeMarketUrl = (
  market: Market,
  path: string,
  alternateLinks: AlternateLink[] = [],
) => {
  const targetAlternateLink = alternateLinks.find(
    (alternateLink) => alternateLink.hrefLang === getFullLanguageTag(market.locale, market.country),
  );

  // Return the alternate link for specific market if it exists
  if (targetAlternateLink) return targetAlternateLink.href.replace(env.COUPLESIDE_URL, '');

  // Otherwise, get a fallback link
  const defaultPath = `/${market.prefix}`;
  switch (true) {
    // Check for missing path in case empty string or incorrect type
    case !path:
      return defaultPath;
    // Location search url
    case path.includes('/search/wedding-'):
      return `${defaultPath}/search`;
    // Supplier profile url
    case isSupplierPage(path):
      return `${defaultPath}/search/wedding-venues`;
    default:
      return defaultPath;
  }
};
