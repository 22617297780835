import { setVariant } from 'app-basic/lib/ab-tests/ab-tests-slice';
import { participatedInABTestAnalytics } from 'app-basic/lib/ab-tests/analytics';
import { getIsTestActivated, getTest } from 'app-basic/lib/ab-tests/selectors';
import { useSelector } from 'app-basic/lib/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Experiments, { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { basicUserIdCookie } from 'app-shared/lib/ab-tests/basic-user-id-cookie';

const SALT = '3b3652a7ca980aa1f7c9a31b44b230f7';

/**
 * Activates an AB Test for app-basic
 *
 * HOW IT WORKS:
 *
 * --- Server-Side Rendering -----------------
 * During server-side rendering, a userId is empty therefore it returns `control`.
 *
 * --- First Client-Side Rendering -----------
 * During the first client-side rendering, the userId is still empty, and it returns `control` as well,
 * so that the versions returned by server and client are matching.
 * If we set a userId immediately, we would get server-client mismatch error.
 *
 * After this, a useEffect kicks in and sets a userId cookie, which returns a proper variant.
 * Between SSR and this moment, there should be a full-page loader covering any "flickering" of variants.
 *
 * --- Subsequent Client-Side Navigation -----
 * During further client-side navigation there is no SSR, but there is already a userId cookie set,
 * thanks to which we can return a proper variant immediately during the first render,
 * so that there is no need for a full-page loader.
 *
 * @param testId - id of the test from firestore
 * @param triggerCondition (optional) - test will run only if this is undefined or true
 */
export const useBasicABTest = (testId: string, triggerCondition?: boolean) => {
  const [userId, setUserId] = useState(basicUserIdCookie.get());

  // The first client-side render has to match the server, so we can set a user id
  // only in the second render using `useEffect`
  useEffect(() => {
    if (userId) return;
    setUserId(basicUserIdCookie.set());
  }, [userId]);

  const abTest = useSelector((state) => getTest(state, testId));
  const isTestActivated = useSelector((state) => getIsTestActivated(state, testId));
  const dispatch = useDispatch();

  // Test should be activated only if:
  const shouldActivateTest =
    // user id is set, and
    userId &&
    // test exists and is active, and
    abTest &&
    // app is not in E2E test mode, and
    !getIsE2E() &&
    // trigger condition is not strictly false (null or undefined is ignored)
    triggerCondition !== false;

  const experiment = shouldActivateTest
    ? new Experiments(
        {
          version: '1.0',
          experiments: [abTest],
          salt: SALT,
        },
        userId,
        { id: userId },
      )
    : null;

  const variant = (experiment?.getCohort(testId) || 'control') as CohortNames;

  /**
   * Mark the test as activated (set variant) to trigger analytics only once.
   *
   * Do it on the client-side only. However, we cannot use useEffect
   * because it would fire the same analytics twice, if a hook from the same test
   * was used in more than once place.
   */
  if (!isTestActivated && shouldActivateTest && typeof window !== 'undefined') {
    dispatch(setVariant({ id: testId, variant }));
    // Send analytics to mixpanel
    dispatch(participatedInABTestAnalytics({ abTest, variant }));
  }

  // We return the variant directly from hook for fast rendering
  return { variant };
};
