import { pick } from 'remeda';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithAdvice } from '@bridebook/toolbox/src/i18n/features';
import type { Slug } from '@bridebook/toolbox/src/types';
import { IArticle } from 'app-shared/lib/articles/types';
import { AlternateLink } from 'lib/app/types';
import { LandingPageIds, WEBFLOW_GLOBAL, WebflowLandingPages } from 'lib/landing/webflow-page-urls';
import { UrlHelper } from 'lib/url-helper';
import { getLocalisedCanonical } from 'lib/utils/i18n-routing/get-localised-canonical';

/**
 * Returns pathname for the current webflow page, including the market prefix
 */
export const getPathnameForWebflow = (pageId: LandingPageIds, market: Market): string => {
  const { bridebookPath } = WebflowLandingPages[pageId];
  // Returns pathname with the first slash replaced by the /MARKET_PREFIX
  // If pathname is empty then returns an empty string
  return bridebookPath.replace(/^[/]/, `/${market.prefix}`);
};

/**
 * Returns a list of alternate links for corresponding pages specified in the Webflow config.
 * If a UK url exists, then it's also added as an x-default link.
 */
export const getAlternateLinksForWebflow = (pageId: LandingPageIds): AlternateLink[] => {
  const { countries, bridebookPath } = WebflowLandingPages[pageId];

  // Create a list of all countries that should be included in the alternate links
  // If the list contains a global folder, then add all countries to the list
  const markets = countries.reduce((acc, countryCode) => {
    if (countryCode === WEBFLOW_GLOBAL) {
      return acc.concat(
        indexedMarkets
          // Do not include countries that are listed in the Webflow config separately to avoid duplicates
          .filter((market) => !countries.includes(market.country)),
      );
    }
    return acc.concat(gazetteer.getMarketByCountry(countryCode));
  }, [] as Market[]);

  return [
    // Add alternate links for all corresponding URLs for different markets
    ...markets.map((market) => ({
      // Market prefix is required for correct handling of locale home (with just a '/' pathname)
      href: getLocalisedCanonical(market, market.prefix + bridebookPath),
      hrefLang: getFullLanguageTag(market.locale, market.country),
    })),
    // If the countries list contains UK or Global, then add an x-default link to UK url
    ...(countries.includes(CountryCodes.GB) || countries.includes(WEBFLOW_GLOBAL)
      ? [
          {
            href: getLocalisedCanonical(
              gazetteer.getMarketByCountry(CountryCodes.GB),
              bridebookPath,
            ),
            hrefLang: 'x-default',
          } as AlternateLink,
        ]
      : []),
  ];
};

/**
 * Returns a list of alternate links for a regular page (so other than webflow, global home etc.)
 * Url to the UK site is always generated also as an x-default
 */
export const getAlternateLinksForRegularPage = (
  pathname?: string,
  markets: Market[] = indexedMarkets,
): AlternateLink[] => [
  ...markets.map((market) => ({
    href: getLocalisedCanonical(market, pathname),
    hrefLang: getFullLanguageTag(market.locale, market.country),
  })),
  {
    href: getLocalisedCanonical(gazetteer.getMarketByCountry(CountryCodes.GB), pathname),
    hrefLang: 'x-default',
  },
];

/**
 * Returns a list of alternate links for search landing page,
 * based on available supplier types for other languages
 */
export const getAlternateLinksForSearchLanding = ({
  pathname,
  supplierType,
}: {
  pathname: string;
  supplierType?: Slug;
}): AlternateLink[] => {
  if (!supplierType) return [];

  const supportedMarkets = indexedMarkets
    // Get only markets supporting given supplier type
    .filter((market) => market.suppliers.includes(supplierType));

  return getAlternateLinksForRegularPage(pathname, supportedMarkets);
};

/**
 * Returns a list of alternate links for a articles fetched from Elastic
 */
export const getAlternateLinksForArticle = (
  articlesI18n: IArticle['i18n'] = {} as IArticle['i18n'],
): AlternateLink[] => {
  // Protection from creating invalid links if there was an unsupported locale in WordPress
  const allowedPrefixes = countriesWithAdvice.map(
    (countryCode) => gazetteer.getMarketByCountry(countryCode).prefix,
  );
  const alternateArticles = pick(articlesI18n, allowedPrefixes);
  const ukMarket = gazetteer.getMarketByCountry(CountryCodes.GB);
  const ukPrefix = ukMarket.prefix;

  return [
    ...Object.entries(alternateArticles).map(([prefix, data]) => {
      const market = gazetteer.getMarketByURL(prefix);
      return {
        href: getLocalisedCanonical(market, UrlHelper.article.id(data.slug)),
        hrefLang: getFullLanguageTag(market.locale, market.country),
      };
    }),
    // If an article for UK exists, use it as an x-default link
    ...(alternateArticles[ukPrefix]
      ? [
          {
            href: getLocalisedCanonical(
              ukMarket,
              UrlHelper.article.id(alternateArticles[ukPrefix].slug),
            ),
            hrefLang: 'x-default',
          } as AlternateLink,
        ]
      : []),
  ];
};

/* ############################################################################
 *  Helpers
 * ######################################################################### */

/**
 * List of markets for which we can generate alternate links
 */
const indexedMarkets = gazetteer
  .getMarkets()
  .filter((market) => market.getLocaleFlags()?.indexable);

/**
 * Returns a full 5-letter language tag
 * If the locale is 2 characters long, it adds the country code to create a full tag
 */
export const getFullLanguageTag = (locale: string, country: CountryCodes) =>
  locale.length === 2 ? `${locale}-${country.toUpperCase()}` : locale;
