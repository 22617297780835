import type { IAddressComponents, Slug } from '@bridebook/toolbox/src/types';
import { Bound } from 'lib/search/types';

export default class SearchFields implements Bound {
  constructor() {
    this.type = 'venue';
    this.sort = 'favourites';
    this.area = '';
    this.swlat = 0;
    this.swlon = 0;
    this.nelat = 0;
    this.nelon = 0;
    this.page = 1;
    this.addressComponents = null;
    this.searchParams = [];
  }

  type: Slug;
  sort: string;
  area: string;
  page: number;
  swlat: number;
  swlon: number;
  nelat: number;
  nelon: number;
  addressComponents: IAddressComponents | null;
  searchParams: Array<string>;
  ourVenue?: string;
  searchType?: string;
}
