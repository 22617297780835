import { BasicStore } from 'app-basic/lib/types';
import createCachedSelector from 're-reselect';
import { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';

const _variants = (state: BasicStore) => state.abtests.variants;
const _tests = (state: BasicStore) => state.abtests.tests;

/**
 * Returns a test with a given id from the list of active tests
 */
export const getTest = createCachedSelector(
  _tests,
  (_: BasicStore, id: string) => id,
  (abtests, id) => abtests?.find((test) => test.id === id),
)((_, id) => id);

/**
 * Returns test variant if activated or a fallback to default
 */
export const getTestVariant = createCachedSelector(
  _variants,
  (_: BasicStore, id: string | undefined) => id || '',
  (abtests, id): CohortNames => abtests[id] || 'control',
)((_, id) => id);

/**
 * Returns true, if the test has a variant in store
 */
export const getIsTestActivated = createCachedSelector(
  _variants,
  (_: BasicStore, id: string) => id,
  (abtests, id) => Object.keys(abtests).includes(id),
)((_, id) => id);
