import { HEADER_I18N_NS } from 'app-basic/lib/i18n/constants';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';

/**
  This function overrides the window.fetch method to automatically add an internationalization header to outgoing fetch requests targeting Next.js data endpoints. The function checks if the code is running in a browser environment (typeof window !== 'undefined') and if the fetch override flag is not already set (window._bbI18nFetchOverriden). If conditions are met, it overrides the window.fetch method.

  When a fetch request is made to a Next.js data endpoint (/_next/data/*.json), it retrieves the current language and associated namespaces from the internationalization store. It then constructs an internationalization header (HEADER_I18N_NS) containing the build ID, language, and sorted list of namespaces. The constructed header is added to the fetch request options. Finally, it calls the original window.fetch method with the modified options.

  The HEADER_I18N_NS is than being processed by the getNamespacesForClient function to filter namespaces based on client information received in the HTTP headers.

  This is required for optimizing the translations namespaces returned by the Next.js server in order to minimize the amount of data transferred between the server and client. By filtering the namespaces based on the client's specified namespaces, the function reduces the amount of redundant data sent to the client, improving performance and reducing latency.
 */
export const addI18nHeaderToFetch = () => {
  if (typeof window === 'undefined' || window._bbI18nFetchOverriden) return;

  window._bbI18nFetchOverriden = true;

  const _originalFetch = window.fetch;

  window.fetch = function (url, options) {
    if (typeof url === 'string' && /\/_next\/data\/.*\.json/.test(url)) {
      const i18n = getI18n();
      const lang = i18n?.language;

      if (lang) {
        const namespaces = Object.keys(i18n.store?.data?.[lang] || {})
          .sort()
          .join(',');

        options = options || {};
        options.headers = options.headers || {};

        // @ts-expect-error
        options.headers[HEADER_I18N_NS] = [env.BUILD_ID, lang, namespaces]
          .filter(Boolean)
          .join(':');
      }
    }

    return _originalFetch.call(window, url, options);
  };

  return _originalFetch;
};
