import { setDevice } from 'app-basic/lib/app/app-slice';
import throttle from 'lodash/throttle';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { getWindowSizes } from 'app-shared/lib/app/utils/get-window-sizes';

export const useDispatchWindowResize = () => {
  const dispatch = useDispatch();

  // This uses `useStore` instead of `useSelector` to avoid calling the effect
  // again when the device changes, which would remove the throttle. The store
  // returned is always the same one and `getState` will return the latest
  // state
  const store = useStore();

  const hasStore = !!store;

  useEffect(() => {
    if (!hasStore || typeof window === 'undefined') return;

    let rafId: null | number = null;
    const handleResize = throttle(() => {
      rafId = window.requestAnimationFrame(() => {
        rafId = null;
        const state = store.getState();
        const sizes = getWindowSizes(false, false);

        dispatch(
          setDevice({
            ...state.app.device,
            ...sizes,
          }),
        );
      });
      // Arbitrary number, similar to: lib/app/epics.ts (windowResizeEpic)
    }, 500);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (typeof rafId === 'number') {
        window.cancelAnimationFrame(rafId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStore]);
};
